<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-12-12 13:33:28
 * @ Description: Overlay to confirm continuation of a job.
 -->

<template>
    <!-- Desktop and Bigger Mobiles -->
    <v-dialog
        v-model="showContinueOverlayBoolean"
        overlay-color="rgb(34, 43, 69)"
        persistent
        scrollable
        v-if="$vuetify.breakpoint.smAndUp"
        width="530px"
    >
        <v-card
            color="rgb(249, 251, 253)"
            height="205px"
        >
            <v-card-text
                class="pa-3 pb-0"
                style="color: black;"
            >
                <v-row
                    style="height: 100%;"
                    align="center"
                >
                    <!-- Job Details -->
                    <v-col
                        cols="4"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <div
                                    style="height: 64px; display: flex; align-items: center;"
                                >
                                    <v-img
                                        :src="jobToContinue.job_banner ? jobToContinue.job_banner : '/general/FixerCompanyIcon.png'"
                                        max-height="64px"
                                        max-width="64px"
                                        :height="$vuetify.breakpoint.mdAndUp && jobToContinue.job_banner ? '64px' : $vuetify.breakpoint.mdAndUp ? '45px' : ''"
                                        :width="$vuetify.breakpoint.mdAndUp && jobToContinue.job_banner ? '64px' : $vuetify.breakpoint.mdAndUp ? '45px' : ''"
                                        contain
                                        class="mx-auto"
                                    />
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                class="os-16-sb text-center text-truncate"
                            >
                                {{ jobToContinue.job_name }}
                            </v-col>
                            <v-col
                                cols="12"
                                class="os-12-sb text-center"
                                style="color: #2E567A;"
                            >
                                <span
                                    class="os-10-r"
                                    style="color: #8F9BB3;"
                                >
                                    Type:
                                </span>
                                <br />
                                {{ jobToContinue.job_type_name }}
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Job Details -->

                    <v-col
                        cols="8"
                        style="min-height: calc(100% + 12px); background-color: rgba(255, 168, 88, 0.25); display: flex; align-items: center;"
                    >
                        <v-row
                            class="px-9 py-3"
                        >
                            <v-col
                                cols="12"
                                class="os-18-sb text-center"
                            >
                                Are you sure you want to
                                <br />
                                continue this job?
                            </v-col>
                            <v-col
                                cols="12"
                                class="text-center pb-0 os-13-sb"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg mr-4 os-13-sb"
                                    color="#2E567A"
                                    @click="toggleContinueOverlayComponent(false)"
                                >
                                    No
                                </v-btn>
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#FFA858"
                                    @click="toggleContinueOverlayComponent(true)"
                                >
                                    Yes
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- Desktop and Bigger Mobiles -->

    <!-- Mobile -->
    <v-dialog
        v-model="showContinueOverlayBoolean"
        overlay-color="rgb(34, 43, 69)"
        persistent
        scrollable
        v-else-if="$vuetify.breakpoint.xs"
        style="z-index: 10;"
        max-width="315px"
    >
        <v-card
            color="rgb(249, 251, 253)"
            max-height="450px"
        >
            <v-card-text
                class="pa-3"
                style="color: black;"
            >
                <v-row
                    style="height: 100%;"
                    align="center"
                >
                    <!-- Job Details -->
                    <v-col
                        cols="12"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <div
                                    style="height: 64px; display: flex; align-items: center;"
                                >
                                    <v-img
                                        :src="jobToContinue.job_banner ? jobToContinue.job_banner : '/general/FixerCompanyIcon.png'"
                                        max-height="64px"
                                        max-width="64px"
                                        :height="$vuetify.breakpoint.mdAndUp && jobToContinue.job_banner ? '64px' : $vuetify.breakpoint.mdAndUp ? '45px' : ''"
                                        :width="$vuetify.breakpoint.mdAndUp && jobToContinue.job_banner ? '64px' : $vuetify.breakpoint.mdAndUp ? '45px' : ''"
                                        contain
                                        class="mx-auto"
                                    />
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                class="os-16-sb text-center text-truncate"
                            >
                                {{ jobToContinue.job_name }}
                            </v-col>
                            <v-col
                                cols="12"
                                class="os-12-sb text-center"
                                style="color: #2E567A;"
                            >
                                <span
                                    class="os-10-r"
                                    style="color: #8F9BB3;"
                                >
                                    Type:
                                </span>
                                <br />
                                {{ jobToContinue.job_type_name }}
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Job Details -->

                    <v-col
                        cols="12"
                        style="background-color: rgba(255, 168, 88, 0.25);"
                    >
                        <v-row
                            class="py-3"
                        >
                            <v-col
                                cols="12"
                                class="os-18-sb text-center"
                            >
                                Are you sure you want to continue this job?
                            </v-col>
                            <v-col
                                cols="12"
                                class="text-center os-13-sb"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg mr-4 os-13-sb"
                                    color="#2E567A"
                                    @click="toggleContinueOverlayComponent(false)"
                                >
                                    No
                                </v-btn>
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#FFA858"
                                    @click="toggleContinueOverlayComponent(true)"
                                >
                                    Yes
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- Mobile -->
</template>

<script>
    export default {
        name: 'WorkGeneralStatusConfirmContinueOverlayComponent',

        props: {
            showContinueOverlayBoolean: {
                type: Boolean,
                required: true
            },

            jobToContinue: {
                type: Object,
                required: true
            }
        },

        methods: {
            toggleContinueOverlayComponent (confirmContinue) {
                this.$emit('toggleContinueOverlayComponent', false, confirmContinue)
            }
        }
    }
</script>
