<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-02-28 14:32:17
 * @ Description: Fixer's job detail page in the user's job dashboard section of the app.
 -->

<template>
    <v-row
        justify="center"
    >
        <!-- Desktop -->
        <v-col
            v-if="$vuetify.breakpoint.mdAndUp"
            :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 1440px;' : 'max-width: 960px;'"
            cols="12"
        >
            <v-row>
                <!-- Heading & Back Button -->
                <v-col
                    cols="12"
                >
                    <v-btn
                        icon
                        @click="back()"
                        class="mb-1"
                    >
                        <v-icon
                            size="24"
                            color="#2E567A"
                            style="opacity: 0.65;"
                        >
                            {{ mdiArrowLeftThinCircleOutline }}
                        </v-icon>
                    </v-btn>
                    <span
                        class="os-22-sb"
                    >
                        Job:
                    </span>
                    <span
                        class="os-22-r"
                    >
                        {{ selectedJob.job_name }}
                    </span>
                    <span
                        class="os-14-r"
                        style="cursor: pointer;"
                        @click="$router.push(`/job/${$route.params.job_id}/view`)"
                    >
                        (Click to view job)
                    </span>
                </v-col>
                <v-col
                    cols="12"
                    class="pt-0"
                >
                    <v-divider />
                </v-col>
                <!-- Heading & Back Button -->

                <!-- Job Detail -->
                <v-col
                    cols="6"
                    class="pb-7"
                    style="max-height: calc(100vh - 190px); overflow-y: auto;"
                >
                    <!-- Rate & Review -->
                    <general-work-rating-review-component
                        class="mb-6"
                        v-if="showReviewSection"
                        :selectedItem="selectedJob"
                        @submitReview="submitReview"
                    />
                    <!-- Rate & Review -->

                    <v-card
                        class="rounded-lg"
                        style="min-height: calc(100vh - 230px);"
                    >
                        <v-card-text
                            style="color: black;"
                        >
                            <v-row
                                justify="center"
                            >
                                <v-col
                                    cols="8"
                                    v-if="selectedJob.applicant_id === $store.state.user.userData.id"
                                >
                                    <v-row>
                                        <v-col
                                            class="flex-shrink-1 flex-grow-0"
                                        >
                                            <v-img
                                                :src="selectedJob.job_banner ? selectedJob.job_banner : '/general/FixerCompanyIcon.png'"
                                                max-height="45px"
                                                max-width="45px"
                                                height="45px"
                                                width="45px"
                                                contain
                                                class="mx-auto"
                                            />
                                        </v-col>
                                        <v-col
                                            class="flex-grow-1 flex-shrink-0"
                                        >
                                            <div
                                                class="os-15-sb"
                                            >
                                                {{ selectedJob.job_name }}
                                            </div>
                                            <div
                                                class="os-13-r"
                                                style="color: #2E567A; opacity: 0.65;"
                                            >
                                                Company: <span @click="$router.push(`/profile/company/${selectedJob.company_id}`)" style="cursor: pointer; text-decoration: underline;">{{ selectedJob.company_name }}</span>
                                            </div>
                                            <div
                                                class="os-13-r text-truncate"
                                            >
                                                <span style="color: #2E567A; opacity: 0.65;">Status:</span> <span class="os-13-sb" :style="selectedJob.job_stage_id === 1 ? 'color: #707070;' : selectedJob.job_stage_id === 2 ? 'color: #FFA858;' : selectedJob.job_stage_id === 3 ? 'color: #08D500;' : selectedJob.job_status_id === 7 ? 'color: #32C5FF;' : 'color: #E02020;'">{{ selectedJob.job_status_name }}</span>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col
                                    cols="8"
                                    v-if="selectedJob.owner_id === $store.state.user.userData.id"
                                >
                                    <v-row>
                                        <v-col
                                            class="flex-shrink-1 flex-grow-0"
                                        >
                                            <v-avatar
                                                height="46px"
                                                width="46px"
                                            >
                                                <v-img
                                                    :src="selectedJob.applicant_avatar ? selectedJob.applicant_avatar : '/general/FixerIndividualIcon.png'"
                                                    contain
                                                />
                                            </v-avatar>
                                        </v-col>
                                        <v-col
                                            class="flex-grow-1 flex-shrink-0"
                                        >
                                            <div
                                                class="os-15-sb"
                                            >
                                                {{ selectedJob.job_name }}
                                            </div>
                                            <div
                                                class="os-13-r text-truncate"
                                                style="color: #2E567A; opacity: 0.65;"
                                            >
                                                Applicant: <span @click="$router.push(`/profile/individual/${selectedJob.applicant_id}`)" style="cursor: pointer; text-decoration: underline;">{{ selectedJob.applicant_first_name }} {{ selectedJob.applicant_last_name }}</span>
                                            </div>
                                            <div
                                                class="os-13-r"
                                            >
                                                <span style="color: #2E567A; opacity: 0.65;">Status:</span> <span class="os-13-sb" :style="selectedJob.job_stage_id === 1 ? 'color: #707070;' : selectedJob.job_stage_id === 2 ? 'color: #FFA858;' : selectedJob.job_stage_id === 3 ? 'color: #08D500;' : selectedJob.job_status_id === 7 ? 'color: #32C5FF;' : 'color: #E02020;'">{{ selectedJob.job_status_name }}</span>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col
                                    cols="4"
                                    class="os-13-r text-right"
                                >
                                    <div
                                        class="os-15-sb"
                                    >
                                        Applied On:
                                    </div>
                                    <div
                                        class="os-13-r mt-1"
                                        style="color: #2E567A;"
                                    >
                                        <v-icon
                                            color="#2E567A"
                                            size="20"
                                            class="mb-1 ml-n1"
                                        >
                                            {{ mdiCalendar }}
                                        </v-icon>
                                        {{ selectedJob.created_date }}
                                    </div>
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="text-right"
                                >
                                    <v-btn
                                        class="white--text no-uppercase rounded-lg os-13-sb mr-1"
                                        color="#E2574C"
                                        v-if="canCancelJob"
                                        @click="confirmCancelJob"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        class="white--text no-uppercase rounded-lg os-13-sb mr-1"
                                        color="#FFA838"
                                        v-if="canPauseJob"
                                        @click="confirmPauseJob"
                                    >
                                        Pause
                                    </v-btn>
                                    <v-btn
                                        class="white--text no-uppercase rounded-lg os-13-sb mr-1"
                                        color="#2E567A"
                                        v-if="canContinueJob"
                                        @click="confirmContinueJob"
                                    >
                                        In Progress
                                    </v-btn>
                                    <v-btn
                                        class="white--text no-uppercase rounded-lg os-13-sb"
                                        color="#069B34"
                                        v-if="canCompleteJob"
                                        @click="confirmCompleteJob"
                                    >
                                        Complete
                                    </v-btn>
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="py-0"
                                >
                                    <v-divider />
                                </v-col>
                                <v-col
                                    cols="12"
                                >
                                    <div
                                        class="os-15-sb"
                                    >
                                        Description:
                                    </div>
                                    <div
                                        class="os-12-r"
                                        v-html="selectedJob.job_description"
                                        v-if="selectedJob.job_description"
                                    /> 
                                    <div
                                        class="os-12-r"
                                        style="color: #8F9BB3;"
                                        v-else
                                    >
                                        No description added
                                    </div>
                                </v-col>

                                <!-- Documents -->
                                <v-col
                                    cols="12"
                                    class="os-15-sb"
                                >
                                    <v-icon
                                        color="black"
                                        size="20"
                                        class="mr-1"
                                    >
                                        {{ mdiCloudDownloadOutline }}
                                    </v-icon>
                                    Document(s)
                                </v-col>
                                <v-col
                                    cols="12"
                                >
                                    <general-document-component
                                        :documents="jobDocuments"
                                        :canEditDocuments="canEditDocuments"
                                        :canDeleteDocuments="canDeleteDocuments"
                                        :fileType="'document'"
                                        @downloadDocument="downloadDocument"
                                        @createDocument="createDocument"
                                        @deleteDocument="confirmDeleteDocument"
                                    />
                                </v-col>
                                <!-- Documents -->

                                <!-- Milestones -->
                                <v-col
                                    cols="12"
                                    class="pb-6"
                                >
                                    <general-work-payment-milestones-component
                                        :canEditPaymentMilestones="canEditPaymentMilestones"
                                        :canPayPaymentMilestones="canPayPaymentMilestones"
                                        :canRefreshPaymentMilestones="canRefreshPaymentMilestones"
                                        :editMode="canEditPaymentMilestones"
                                        :paymentMilestones="paymentMilestones"
                                        :canViewPaidPaymentMilestones="canViewPaidPaymentMilestones"
                                        @refreshPaymentMilestones="getJobPaymentMilestones"
                                    />
                                </v-col>
                                <!-- Milestones -->
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <!-- Job Detail -->

                <!-- Messages -->
                <v-col
                    cols="6"
                    class="pb-0"
                    style="height: calc(100vh - 190px); max-height: calc(100vh - 190px); overflow-y: auto;"
                >
                    <general-work-messaging-component
                        :selectedItem="selectedJob"
                        :canSendMessages="canSendMessages"
                        @resetUnreadMessageCount="resetUnreadMessageCount"
                    />
                </v-col>
                <!-- Messages -->
            </v-row>
        </v-col>
        <!-- Desktop -->

        <!-- Mobile -->
        <v-col
            v-if="$vuetify.breakpoint.smAndDown"
            :style="$store.state.general.customBreakpoints.xxs ? 'max-width: 280px;' : $vuetify.breakpoint.xs ? 'max-width: 360px;' : 'max-width: 500px'"
            cols="12"
        >
            <v-row>
                <!-- Heading & Back Button -->
                <v-col
                    cols="12"
                >
                    <v-btn
                        icon
                        @click="back()"
                        class="mb-1"
                    >
                        <v-icon
                            size="24"
                            color="#2E567A"
                            style="opacity: 0.65;"
                        >
                            {{ mdiArrowLeftThinCircleOutline }}
                        </v-icon>
                    </v-btn>
                    <span
                        class="os-22-sb"
                    >
                        Job:
                    </span>
                    <span
                        class="os-22-r"
                    >
                        {{ selectedJob.job_name }}
                    </span>
                    <br v-if="$vuetify.breakpoint.xs" />
                    <span
                        class="os-14-r"
                        style="cursor: pointer;"
                        @click="$router.push(`/job/${$route.params.job_id}/view`)"
                    >
                        (Click to view job)
                    </span>
                </v-col>
                <!-- Heading & Back Button -->

                <!-- Action Buttons -->
                <v-col
                    cols="12"
                    class="text-center text-sm-right"
                >
                    <v-btn
                        class="white--text no-uppercase rounded-lg os-13-sb mr-1"
                        color="#E2574C"
                        v-if="canCancelJob"
                        @click="confirmCancelJob"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        class="white--text no-uppercase rounded-lg os-13-sb mr-1"
                        color="#2E567A"
                        v-if="canContinueJob"
                        @click="confirmContinueJob"
                    >
                        In Progress
                    </v-btn>
                    <v-btn
                        class="white--text no-uppercase rounded-lg os-13-sb mr-1"
                        color="#FFA838"
                        v-if="canPauseJob"
                        @click="confirmPauseJob"
                    >
                        Pause
                    </v-btn>
                    <v-btn
                        class="white--text no-uppercase rounded-lg os-13-sb"
                        color="#069B34"
                        v-if="canCompleteJob"
                        @click="confirmCompleteJob"
                    >
                        Complete
                    </v-btn>
                    <span
                        class="os-13-r"
                        style="color: #2E567A; opacity: 0.65;"
                        v-if="selectedJob.job_status_id === 4 && selectedJob.applicant_id === $store.state.user.userData.id"
                    >
                        Paused
                    </span>
                </v-col>
                <v-col
                    cols="12"
                    class="pt-0"
                >
                    <v-divider />
                </v-col>
                <!-- Action Buttons -->

                <!-- Job Detail -->
                <v-col
                    cols="12"
                >
                    <!-- Rate & Review -->
                    <general-work-rating-review-component
                        class="mb-6"
                        v-if="showReviewSection"
                        :selectedItem="selectedJob"
                        @submitReview="submitReview"
                    />
                    <!-- Rate & Review -->

                    <v-card
                        class="rounded-lg"
                    >
                        <v-card-text
                            style="color: black;"
                        >
                            <v-row
                                justify="center"
                            >
                                <v-col
                                    class="flex-shrink-1 flex-grow-0"
                                >
                                    <v-avatar
                                        height="46px"
                                        width="46px"
                                        v-if="selectedJob.owner_id === $store.state.user.userData.id"
                                    >
                                        <v-img
                                            :src="selectedJob.company_avatar ? selectedJob.company_avatar : '/general/FixerIndividualIcon.png'"
                                            contain
                                        />
                                    </v-avatar>
                                    <v-img
                                        :src="selectedJob.job_banner ? selectedJob.job_banner : '/general/FixerCompanyIcon.png'"
                                        max-height="45px"
                                        max-width="45px"
                                        height="45px"
                                        width="45px"
                                        contain
                                        class="mx-auto"
                                        v-if="selectedJob.applicant_id === $store.state.user.userData.id"
                                    />
                                </v-col>
                                <v-col
                                    class="flex-grow-1 flex-shrink-0 text-right text-sm-left pl-0"
                                >
                                    <div
                                        class="os-15-sb"
                                    >
                                        {{ selectedJob.job_name }}
                                    </div>
                                    <div
                                        class="os-13-r"
                                        style="color: #2E567A; opacity: 0.65;"
                                        v-if="selectedJob.owner_id === $store.state.user.userData.id"
                                    >
                                        Applicant: <span @click="$router.push(`/profile/individual/${selectedJob.applicant_id}`)" style="cursor: pointer; text-decoration: underline;">{{ selectedJob.applicant_first_name }} {{ selectedJob.applicant_last_name }}</span>
                                    </div>
                                    <div
                                        class="os-13-r"
                                        style="color: #2E567A; opacity: 0.65;"
                                        v-if="selectedJob.applicant_id === $store.state.user.userData.id"
                                    >
                                        Company: <span @click="$router.push(`/profile/company/${selectedJob.company_id}`)" style="cursor: pointer; text-decoration: underline;">{{ selectedJob.company_name }}</span>
                                    </div>
                                    <div
                                        class="os-13-r text-truncate"
                                    >
                                        <span style="color: #2E567A; opacity: 0.65;">Status:</span> <span class="os-13-sb" :style="selectedJob.job_stage_id === 1 ? 'color: #707070;' : selectedJob.job_stage_id === 2 ? 'color: #FFA858;' : selectedJob.job_stage_id === 3 ? 'color: #08D500;' : selectedJob.job_status_id === 7 ? 'color: #32C5FF;' : 'color: #E02020;'">{{ selectedJob.job_status_name }}</span>
                                    </div>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="4"
                                    class="os-13-r text-right pt-0 pt-sm-3"
                                >
                                    <span
                                        class="os-15-sb"
                                    >
                                        Applied On: 
                                    </span>
                                    <br v-if="$vuetify.breakpoint.sm" />
                                    <span
                                        class="os-13-r mt-1"
                                        style="color: #2E567A;"
                                    >
                                        <v-icon
                                            color="#2E567A"
                                            size="20"
                                            class="mb-1 ml-n1"
                                            v-if="$vuetify.breakpoint.sm"
                                        >
                                            {{ mdiCalendar }}
                                        </v-icon>
                                        {{ selectedJob.created_date }}
                                    </span>
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="py-0"
                                >
                                    <v-divider />
                                </v-col>
                                <v-col
                                    cols="12"
                                >
                                    <div
                                        class="os-15-sb"
                                    >
                                        Description:
                                    </div>
                                    <div
                                        class="os-12-r"
                                        v-html="selectedJob.job_description"
                                        v-if="selectedJob.job_description"
                                    /> 
                                    <div
                                        class="os-12-r"
                                        style="color: #8F9BB3;"
                                        v-else
                                    >
                                        No description added
                                    </div>
                                </v-col>

                                <!-- Documents -->
                                <v-col
                                    cols="12"
                                    class="os-15-sb"
                                >
                                    <v-icon
                                        color="black"
                                        size="20"
                                        class="mr-1"
                                    >
                                        {{ mdiCloudDownloadOutline }}
                                    </v-icon>
                                    Document(s)
                                </v-col>
                                <v-col
                                    cols="12"
                                >
                                    <general-document-component
                                        :documents="jobDocuments"
                                        :canEditDocuments="canEditDocuments"
                                        :canDeleteDocuments="canDeleteDocuments"
                                        :fileType="'document'"
                                        @downloadDocument="downloadDocument"
                                        @createDocument="createDocument"
                                        @deleteDocument="confirmDeleteDocument"
                                    />
                                </v-col>
                                <!-- Documents -->

                                <!-- Milestones -->
                                <v-col
                                    cols="12"
                                >
                                    <general-work-payment-milestones-component
                                        :canEditPaymentMilestones="canEditPaymentMilestones"
                                        :canPayPaymentMilestones="canPayPaymentMilestones"
                                        :canRefreshPaymentMilestones="canRefreshPaymentMilestones"
                                        :editMode="canEditPaymentMilestones"
                                        :paymentMilestones="paymentMilestones"
                                        :canViewPaidPaymentMilestones="canViewPaidPaymentMilestones"
                                        @refreshPaymentMilestones="getJobPaymentMilestones"
                                    />
                                </v-col>
                                <!-- Milestones -->
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <!-- Job Detail -->

                <!-- Messages -->
                <v-col
                    cols="12"
                    class="pb-9"
                >
                    <general-work-messaging-component
                        :selectedItem="selectedJob"
                        :canSendMessages="canSendMessages"
                        @resetUnreadMessageCount="resetUnreadMessageCount"
                    />
                </v-col>
                <!-- Messages -->
            </v-row>
        </v-col>
        <!-- Mobile -->

        <!-- Confirm Complete Job Overlay -->
        <work-general-status-confirm-complete-overlay-component
            :showCompleteOverlayBoolean="showCompleteOverlayBoolean"
            :jobToComplete="selectedJob"
            @toggleCompleteOverlayComponent="toggleCompleteOverlayComponent"
        />
        <!-- Confirm Complete Job Overlay -->

        <!-- Confirm Cancel Job Overlay -->
        <work-general-status-confirm-cancel-overlay-component
            :showCancelOverlayBoolean="showCancelOverlayBoolean"
            :jobToCancel="selectedJob"
            @toggleCancelOverlayComponent="toggleCancelOverlayComponent"
        />
        <!-- Confirm Cancel Job Overlay -->

        <!-- Confirm Continue Job Overlay -->
        <work-general-status-confirm-in-progress-overlay-component
            :showContinueOverlayBoolean="showContinueOverlayBoolean"
            :jobToContinue="selectedJob"
            @toggleContinueOverlayComponent="toggleContinueOverlayComponent"
        />
        <!-- Confirm Continue Job Overlay -->

        <!-- Confirm Pause Job Overlay -->
        <work-general-status-confirm-pause-overlay-component
            :showPauseOverlayBoolean="showPauseOverlayBoolean"
            :jobToPause="selectedJob"
            @togglePauseOverlayComponent="togglePauseOverlayComponent"
        />
        <!-- Confirm Pause Job Overlay -->

        <!-- Confirm Delete Overlay -->
        <general-confirm-delete-overlay-component
            :showDeleteConfirmationOverlayBoolean="showDeleteConfirmationOverlayBoolean"
            :itemToDeleteType="'Document'"
            @showDeleteConfirmationOverlay="showDeleteConfirmationOverlay"
        />
        <!-- Confirm Delete Overlay -->
    </v-row>
</template>
<script>
    import GeneralWorkPaymentMilestonesComponent from '@/components/general/work/GeneralWorkPaymentMilestonesComponent.vue'
    import { mdiCalendar, mdiCloudDownloadOutline, mdiArrowLeftThinCircleOutline } from '@mdi/js'
    import GeneralWorkMessagingComponent from '@/components/general/work/GeneralWorkMessagingComponent.vue'
    import GeneralDocumentComponent from '@/components/general/document/GeneralDocumentComponent.vue'
    import GeneralWorkRatingReviewComponent from '@/components/general/work/GeneralWorkRatingReviewComponent.vue'
    import { WorkJobApplicationChatController, WorkJobApplicationDetailController, WorkJobApplicationMilestoneController, WorkJobStatusController, WorkJobDocumentController } from '@/logic/controller/index.controller.js'
    import WorkGeneralStatusConfirmCompleteOverlayComponent from '@/overlays/work/general/status/WorkGeneralStatusConfirmCompleteOverlayComponent.vue'
    import WorkGeneralStatusConfirmCancelOverlayComponent from '@/overlays/work/general/status/WorkGeneralStatusConfirmCancelOverlayComponent.vue'
    import WorkGeneralStatusConfirmInProgressOverlayComponent from '@/overlays/work/general/status/WorkGeneralStatusConfirmInProgressOverlayComponent.vue'
    import WorkGeneralStatusConfirmPauseOverlayComponent from '@/overlays/work/general/status/WorkGeneralStatusConfirmPauseOverlayComponent.vue'
    import GeneralConfirmDeleteOverlayComponent from '@/overlays/general/GeneralConfirmDeleteOverlayComponent.vue'
    import Vue from 'vue'

    export default {
        name: 'WorkJobDetailGeneralOverviewPage',

        components: {
            GeneralWorkPaymentMilestonesComponent,
            GeneralWorkMessagingComponent,
            GeneralDocumentComponent,
            GeneralWorkRatingReviewComponent,
            WorkGeneralStatusConfirmCompleteOverlayComponent,
            WorkGeneralStatusConfirmCancelOverlayComponent,
            WorkGeneralStatusConfirmInProgressOverlayComponent,
            WorkGeneralStatusConfirmPauseOverlayComponent,
            GeneralConfirmDeleteOverlayComponent
        },

        computed: {
            canEditPaymentMilestones () {
                // Pending
                if ((this.selectedJob.job_stage_id === 2) && (this.selectedJob.owner_id === this.$store.state.user.userData.id)) return true
                else return false
            },

            canPayPaymentMilestones () {
                // Active
                if (this.selectedJob.job_stage_id === 3 && this.selectedJob.owner_id === this.$store.state.user.userData.id) return true
                else return false
            },

            canViewPaidPaymentMilestones () {
                // Active
                if (this.selectedJob.job_stage_id === 3 && this.selectedJob.applicant_id === this.$store.state.user.userData.id) return true
                else return false
            },

            canRefreshPaymentMilestones () {
                if (this.selectedJob.applicant_id === this.$store.state.user.userData.id && this.selectedJob.job_stage_id === 3) return true
                else return false
            },

            canEditDocuments () {
                // Not in review process or History stage
                if (this.selectedJob.job_status_id !== 18 && this.selectedJob.job_status_id !== 20 && this.selectedJob.job_status_id !== 21 && this.selectedJob.job_stage_id !== 4) return true
                else return false
            },

            canDeleteDocuments () {
                // Only owner can delete
                if (this.selectedJob.owner_id === this.$store.state.user.userData.id) {
                    // Not in review process or History stage
                    if (this.selectedJob.job_status_id !== 18 && this.selectedJob.job_status_id !== 20 && this.selectedJob.job_status_id !== 21 && this.selectedJob.job_stage_id !== 4) return true
                    else return false
                } else return false
            },

            canCancelJob () {
                // In progress
                if (this.selectedJob.job_status_id === 3) return true
                // Paused
                else if (this.selectedJob.job_status_id === 4 && this.selectedJob.owner_id === this.$store.state.user.userData.id) return true
                // Cancelled by candidate/applicant
                else if (this.selectedJob.job_status_id === 12 && (this.selectedJob.owner_id === this.$store.state.user.userData.id)) return true
                // Cancelled by owner
                else if (this.selectedJob.job_status_id === 13 && (this.selectedJob.applicant_id === this.$store.state.user.userData.id)) return true
                else return false
            },

            canCompleteJob () {
                // In progress
                if (this.selectedJob.job_status_id === 3) return true
                // Completed by candidate/applicant
                else if (this.selectedJob.job_status_id === 14 && (this.selectedJob.owner_id === this.$store.state.user.userData.id)) return true
                // Completed by owner
                else if (this.selectedJob.job_status_id === 15 && (this.selectedJob.applicant_id === this.$store.state.user.userData.id)) return true
                else return false
            },

            canContinueJob () {
                // Cancelled by owner
                if (this.selectedJob.job_status_id === 13 && (this.selectedJob.owner_id === this.$store.state.user.userData.id)) return true
                // Cancelled by candidate
                else if (this.selectedJob.job_status_id === 12 && (this.selectedJob.applicant_id === this.$store.state.user.userData.id)) return true
                // Completed by owner
                else if (this.selectedJob.job_status_id === 15 && (this.selectedJob.owner_id === this.$store.state.user.userData.id)) return true
                // Completed by candidate
                else if (this.selectedJob.job_status_id === 14 && (this.selectedJob.applicant_id === this.$store.state.user.userData.id)) return true
                // Paused
                else if (this.selectedJob.job_status_id === 4 && (this.selectedJob.owner_id === this.$store.state.user.userData.id)) return true
                else return false
            },

            showReviewSection () {
                // In review process
                if (this.selectedJob.job_status_id === 18 || this.selectedJob.job_status_id === 20 || this.selectedJob.job_status_id === 21) return true
                else return false
            },

            canSendMessages () {
                // History stage
                if (this.selectedJob.job_stage_id === 4) return false
                else return true
            },

            canPauseJob () {
                if (this.selectedJob.owner_id === this.$store.state.user.userData.id) {
                    // In Progress
                    if (this.selectedJob.job_status_id === 3) return true
                    else return false
                } else return false
            }
        },

        watch: {
            '$store.state.socketIO.initialized': {
                async handler (value) {
                    this.workJobApplicationDetailController = new WorkJobApplicationDetailController()

                    if (value) this.listenForStatusUpdates()
                },
                immediate: true
            },

            '$route.params.job_id': {
                async handler (value) {
                    await this.getJobDetail()
                    await this.getJobPaymentMilestones()
                }
            },

            '$route.params.applicant_id': {
                async handler (value) {
                    await this.getJobDetail()
                    await this.getJobPaymentMilestones()
                }
            }
        },

        data () {
            return {
                mdiCalendar,
                mdiCloudDownloadOutline,
                mdiArrowLeftThinCircleOutline,
                workJobApplicationChatController: null,
                workJobApplicationDetailController: null,
                workJobApplicationMilestoneController: null,
                workJobDocumentController: null,
                selectedJob: {},
                paymentMilestones: [],
                jobDocuments: [],
                showCompleteOverlayBoolean: false,
                showCancelOverlayBoolean: false,
                showContinueOverlayBoolean: false,
                showPauseOverlayBoolean: false,
                workJobStatusController: null,
                documentToDelete: null,
                showDeleteConfirmationOverlayBoolean: false
            }
        },

        beforeMount () {
            this.workJobApplicationChatController = new WorkJobApplicationChatController()
            this.workJobApplicationMilestoneController = new WorkJobApplicationMilestoneController()
            this.workJobStatusController = new WorkJobStatusController()
            this.workJobDocumentController = new WorkJobDocumentController()
        },

        async mounted () {
            await this.getJobDetail()
            await this.getJobDocuments()
            await this.getJobPaymentMilestones()

            if (this.$store.state.socketIO.initialized) this.listenForStatusUpdates()
        },

        methods: {
            async getJobDetail () {
                try {
                    this.selectedJob = await this.workJobApplicationDetailController.retrieve(this.$route.params.job_id, this.$route.params.applicant_id)

                    if (this.selectedJob.created_date) {
                        const removeTime = this.selectedJob.created_date.split(' ')[0]
                        const convertDate = new Date(removeTime)
                        const splitConvertedDate = convertDate.toString().split(' ')
                        this.selectedJob.created_date = splitConvertedDate[2] + ' ' + splitConvertedDate[1] + ' ' + splitConvertedDate[3]
                    }
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getJobDocuments () {
                try {
                    this.jobDocuments = await this.workJobDocumentController.retrieve(this.$route.params.job_id)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getJobPaymentMilestones () {
                try {
                    this.paymentMilestones = await this.workJobApplicationMilestoneController.retrieve(this.$route.params.job_id, this.$route.params.applicant_id)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            back () {
                if (this.selectedJob.job_stage_id === 3) this.$router.push('/job/active')
                else if (this.selectedJob.job_stage_id === 4) this.$router.push('/job/history')
            },

            async createDocument(document) {
                try {
                    this.jobDocuments = await this.workJobDocumentController.create(this.$route.params.job_id, document)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async downloadDocument(documentToDownload) {
                try {
                    const downloadResponse = await this.workJobDocumentController.download(this.$route.params.job_id, encodeURIComponent(documentToDownload.fullpath))

                    var data = new Blob([downloadResponse])
                    if (typeof window.navigator.msSaveBlob === 'function') {
                        window.navigator.msSaveBlob(data, documentToDownload.filename)
                    } else {
                        var blob = data
                        var link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = documentToDownload.filename

                        document.body.appendChild(link)

                        link.click()
                    }

                    this.$store.commit('setSnackbar', {
                        text: 'Downloaded successfully',
                        show: true,
                        color: '#069B34'
                    })
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async confirmDeleteDocument(document) {
                this.documentToDelete = document
                this.showDeleteConfirmationOverlay(true)
            },

            async deleteDocument(document) {
                try {
                    this.jobDocuments = await this.workJobDocumentController.delete(this.$route.params.job_id, [document])

                    this.$store.commit('setSnackbar', {
                        text: 'Deleted successfully',
                        show: true,
                        color: '#069B34'
                    })

                    this.documentToDelete = null
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            showDeleteConfirmationOverlay (value, confirmDelete) {
                this.showDeleteConfirmationOverlayBoolean = value

                if (confirmDelete) this.deleteDocument(this.documentToDelete)
            },

            confirmCompleteJob () {
                this.toggleCompleteOverlayComponent(true)
            },

            toggleCompleteOverlayComponent (value, confirmCompletion) {
                this.showCompleteOverlayBoolean = value

                if (confirmCompletion) this.completeJob()
            },

            async completeJob () {
                try {
                    const response = await this.workJobStatusController.complete(this.selectedJob)

                    this.selectedJob.job_status_id = response.status_id
                    this.selectedJob.job_status_name = response.status_name
                    this.selectedJob.job_stage_id = response.stage_id

                    this.$store.commit('setSnackbar', {
                        text: 'Updated successfully',
                        show: true,
                        color: '#069B34'
                    })
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            confirmCancelJob () {
                this.toggleCancelOverlayComponent(true)
            },

            toggleCancelOverlayComponent (value, confirmCancellation) {
                this.showCancelOverlayBoolean = value

                if (confirmCancellation) this.cancelJob()
            },

            async cancelJob () {
                try {
                    const response = await this.workJobStatusController.cancel(this.selectedJob)

                    this.selectedJob.job_status_id = response.status_id
                    this.selectedJob.job_status_name = response.status_name
                    this.selectedJob.job_stage_id = response.stage_id

                    if (response.status_id === 6) {
                        this.$store.commit('setSnackbar', {
                            text: 'Job cancelled',
                            show: true,
                            color: '#069B34'
                        })

                        this.$router.push('/job/history')
                    } else {
                        this.$store.commit('setSnackbar', {
                            text: 'Updated successfully',
                            show: true,
                            color: '#069B34'
                        })
                    }
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            confirmContinueJob () {
                this.toggleContinueOverlayComponent(true)
            },

            toggleContinueOverlayComponent (value, confirmContinuation) {
                this.showContinueOverlayBoolean = value

                if (confirmContinuation) this.continueJob()
            },

            async continueJob () {
                try {
                    const response = await this.workJobStatusController.inProgress(this.selectedJob)

                    this.selectedJob.job_status_id = response.status_id
                    this.selectedJob.job_status_name = response.status_name
                    this.selectedJob.job_stage_id = response.stage_id

                    this.$store.commit('setSnackbar', {
                        text: 'Updated successfully',
                        show: true,
                        color: '#069B34'
                    })
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            confirmPauseJob () {
                this.togglePauseOverlayComponent(true)
            },

            togglePauseOverlayComponent (value, confirmPause) {
                this.showPauseOverlayBoolean = value

                if (confirmPause) this.pauseJob()
            },

            async pauseJob () {
                try {
                    this.selectedJob.user_id = this.$store.state.user.userData.id
                    const response = await this.workJobStatusController.pause(this.selectedJob)

                    this.selectedJob.job_status_id = response.status_id
                    this.selectedJob.job_status_name = response.status_name
                    this.selectedJob.job_stage_id = response.stage_id

                    this.$store.commit('setSnackbar', {
                        text: 'Updated successfully',
                        show: true,
                        color: '#069B34'
                    })
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async submitReview (rating, review) {
                try {
                    const reviewToSubmit = {
                        rating,
                        review,
                        work_id: this.selectedJob.job_id,
                        reviewer_id: this.$store.state.user.userData.id,
                        reviewee_id: this.$store.state.user.userData.id === this.selectedJob.owner_id ? this.selectedJob.applicant_id : this.selectedJob.owner_id
                    }

                    const response = await this.workJobStatusController.review(reviewToSubmit)

                    this.selectedJob.job_status_id = response.status_id
                    this.selectedJob.job_status_name = response.status_name
                    this.selectedJob.job_stage_id = response.stage_id

                    if (response.status_id === 7) {
                        this.$store.commit('setSnackbar', {
                            text: 'Job completed',
                            show: true,
                            color: '#069B34'
                        })

                        this.$router.push('/job/history')
                    } else {
                        this.$store.commit('setSnackbar', {
                            text: 'Review submitted successfully',
                            show: true,
                            color: '#069B34'
                        })
                    }
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async listenForStatusUpdates () {
                const userID = this.$store.state.user.userData.id

                await this.getJobDetail()

                Vue.prototype.$socketIO.on(`user/${userID}/job/${this.selectedJob.job_id}/status`, async (notification) => {
                    this.selectedJob.job_status_id = Number(notification.message.work_status_id)
                    this.selectedJob.job_status_name = notification.message.work_status_name
                    this.selectedJob.job_stage_id = Number(notification.message.work_stage_id)
                })
            },

            resetUnreadMessageCount () {
                try {
                    this.workJobApplicationChatController.resetMessageCount(this.selectedJob)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            }
        }
    }
</script>
